import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget10,
  MixedWidget8,
  MixedWidget5,
  MixedWidget3,
} from '../../../_metronic/partials/widgets'
import {
  
  Link
} from "react-router-dom";
import axios from "axios";

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
    </>
  )
}
let dashboardData;

function getDashboardPageData() {
  axios
      .get('https://api.lastflightmarketing.com/api/admin/getdashboarddata')
      .then((response) => response.data).then((response) => {
      dashboardData = response.data;
  })
}
getDashboardPageData();

const DashboardWrapper = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/*<DashboardPage />*/}

      <div className={'row'}>
        <div className={'col'}>
          <h3>EMI Details</h3>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Total EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboardData.total_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Paid EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboardData.paid_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Unpaid EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboardData.unpaid_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Pending EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboardData.pending_emis}
              </div>
            </div>
          </div>
          <div className={'bg-info d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Upcoming EMIs
              </div>
              <div className={'col text-end text-white'}>
                {dashboardData.upcoming_emis}
              </div>
            </div>
          </div>
        </div>
        <div className={'col'}>
          <h3>Finance Details</h3>
          <div className={'bg-primary d-block w-100 my-2 fw-bold rounded-pill'}>
            <div className={'row p-5'}>
              <div className={'col text-start text-white'}>
                Total Finances
              </div>
              <div className={'col text-end text-white'}>
                1
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
